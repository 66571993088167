<template>
    <div class="notif-container">
        <div class="banner">
            <h2> اعلانات </h2>
        </div>
        <div class="text-container">
            <ShowInfoDropDown :data="notifs" />
        </div>
    </div>
</template>

<script>
import ShowInfoDropDown from '../PublicComponents/ShowInfoDropDown.vue'
    export default {
        name:'Notifications',
        components:{
            ShowInfoDropDown
        },
        data() {
            return {
                notifs:[
                    {
                        answer: "شما می توانید رمزارزهای خود را به مدت طولانی در کیف پول های گرم که همان نرم افزارهای آنلاین هستند و کیف پول های سرد یا همان سخت افزارهای نگهداری رمزارز می باشند، نگهداری کنید. قائدتا هر کدام از آنها معایب و مزایایی دارند.",
                        id: 1,
                        question: "رمزارزها یا همان ارزهای دیجیتال را چگونه نگهداری کنیم؟",
                        createdAtDateTime: "2022-06-01T15:09:47"
                    },
                    {
                        answer: "شما می توانید رمزارزهای خود را به مدت طولانی در کیف پول های گرم که همان نرم افزارهای آنلاین هستند و کیف پول های سرد یا همان سخت افزارهای نگهداری رمزارز می باشند، نگهداری کنید. قائدتا هر کدام از آنها معایب و مزایایی دارند.",
                        id: 2,
                        question: "رمزارزها یا همان ارزهای دیجیتال را چگونه نگهداری کنیم؟",
                        createdAtDateTime: "2022-06-01T15:09:47"
                    }
                ],
                allmessages:[],
                allTotalElements:[],
            }
        },
        methods:{
            async getMessages() {
                this.state.loading = true
                const res = await this.$axios.get('/users/notifications')
                    // {
                    //     params: {
                    //          page: page, size: perPage,
                    //     }
                    // })
                    console.log(res);
                if (res) {
                    this.allmessages.push(...res.content)
                    this.allTotalElements = res.totalElements
                    console.log(this.allmessages);
                }
            },
        },
        mounted() {
            // this.getMessages()
        },
    }
</script>

<style lang="scss" scoped>
.notif-container{
    display: flex;
    flex-direction: column;
    background: var(--homepage-bg);
    row-gap: 72px;   
}
.banner{
    background: url('../../assets/notifsBanner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 100%;
    height: 450px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 100px 0;
    h2{
        font-weight: 700;
        font-size: clamp(28px,3vw,36px);
        color: var(--black);
    }
}
.text-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 72px;
    padding: 40px 120px;
}
@media only screen and(max-width:1100px){
    .text-container{
        padding: 20px;
    }
    .banner{
        background: unset !important;
        height: 250px !important;
    }
}
</style>